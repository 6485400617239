import { catchError, filter, map, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { StorageService } from './storage.service';
import {
  User,
  UserLoginResult,
  UserAvailableRoles,
  UserTypeRole,
} from '@app/shared/models';
import { HttpClient } from '@angular/common/http';
import { NodeApiService } from '@app/shared/services/node-api.service';
import { BookingStates } from '@app/shared/models/enum/bookingstates';
import { UserRoles } from '@app/shared/models/enum/userroles';
import * as Sentry from '@sentry/angular-ivy';
import { ConfigService } from '@app/shared/services/config.service';
import {
  emailVerifyAction,
  logoutAction,
  phoneVerifyAction,
  setCurrentUserAction,
  setCurrentUserPreferenceAction,
  setUserAction,
  smsVerifyAction,
} from '@app/shared/actions/shared.actions';
import {
  getAuthToken,
  getCurrentUser,
  getCurrentUserPreference,
} from '@app/shared/reducers/user.selectors';
import { MapUserSearchResult } from '@app/shared/models/map-user/map-user-search-result';
import { MapUser } from '@app/shared/models/map-user/map-user';
import { MapUserSearchCommand } from '@app/shared/models/map-user/map-user-search-command';
import { Router } from '@angular/router';
import { ChatUser } from '@app/shared/models/conversation/chat-user';
import { GridUserDetails } from '@app/shared/models/grid/grid-user-details';
import { CanBookModel } from '@app/shared/models/booking/can-book.model';
import {
  CurrentUser,
  ProfileApprovedStatus,
} from '@app/shared/models/current-user';
import {
  SetUserPreferenceCommand,
  UserPreferenceModel,
} from '@app/shared/models/user-preference.model';
import { MatDialog } from '@angular/material/dialog';
import { IntercomService } from '@app/shared/services/intercom.service';
import { _t } from '@app/shared/helpers';
import { ToastType } from '@app/shared/models/toast.model';
import { NotificationService } from '@app/shared/services/notification.service';
import { L10nTranslationService } from 'angular-l10n';
import { CommonService } from '@app/instafeature/services/common.service';
import { getInstaStateSelector } from '@app/instafeature/store/instafeature.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly dialog = inject(MatDialog);

  private followersSubject = new BehaviorSubject<Array<any>>([]);
  public followers = this.followersSubject.asObservable();

  public followerDeletedSubject = new BehaviorSubject<string>(null);
  public followerDeleted = this.followerDeletedSubject.asObservable();

  // Pro user
  private isProUserSubject = new BehaviorSubject<boolean>(false);
  public isProUser = this.isProUserSubject.asObservable();

  private roleSubject = new BehaviorSubject<any>({ isGuest: true });
  public role = this.roleSubject.asObservable();

  private first_time_wizard;
  // variable to know what parts of views need to display

  private allBookingsSubject = new Subject<any>();
  public allBookings = this.allBookingsSubject.asObservable();
  private bookingsSubject = new BehaviorSubject<any>(null);
  public bookings = this.bookingsSubject.asObservable();
  accessToken: string;
  readonly notifyService = inject(NotificationService);
  readonly translation = inject(L10nTranslationService);
  readonly commonService = inject(CommonService);
  readonly instaState = this.store.selectSignal(getInstaStateSelector);

  readonly userPreference = this.store.selectSignal(getCurrentUserPreference);
  readonly user = this.store.selectSignal(getCurrentUser);

  /**
   * @param {object} user
   * @returns {string} user base role name like "client" || "agency" etc
   */
  public static getUserBaseRole(user: any): string {
    let baseRole: string = 'default';

    if (user) {
      if (user.roleId !== undefined) {
        for (const role in UserAvailableRoles) {
          if (UserAvailableRoles[role].roles.indexOf(user.roleId) !== -1) {
            baseRole = role;
          }
        }
      } else if (user.role !== undefined) {
        // if no role id
        let roleId;
        // we found it by role name -> user.role
        for (const prop in UserRoles) {
          if (prop === user.role) {
            roleId = UserRoles[prop];
          }
        }
        // now we know Id of current role
        for (const role in UserAvailableRoles) {
          if (UserAvailableRoles[role].roles.indexOf(roleId) !== -1) {
            baseRole = role;
          }
        }
      }
    }

    return baseRole;
  }

  /**
   *
   * @param {string} checkingRole
   * @param {User} user
   * @returns {boolean}
   */
  public static checkUserRole(checkingRole: string, user: User): boolean {
    const currentUserBaseRole = UserService.getUserBaseRole(user);
    let checkResult = false;

    if (checkingRole === currentUserBaseRole) {
      checkResult = true;
    }

    return checkResult;
  }

  public static getUserRole(user: User): UserTypeRole {
    if (!user) {
      return { isGuest: true };
    } else {
      return {
        isClient: UserService.checkUserRole(
          UserAvailableRoles.client.baseRoleName,
          user
        ),
        isGingr: UserService.checkUserRole(
          UserAvailableRoles.gingr.baseRoleName,
          user
        ),
        isEstablishment: UserService.checkUserRole(
          UserAvailableRoles.brothels.baseRoleName,
          user
        ),
        isAgency: UserService.checkUserRole(
          UserAvailableRoles.agency.baseRoleName,
          user
        ),
      };
    }
  }

  public static getAllAvailableStatuses(): number[] {
    return [
      BookingStates.ACCEPTED,
      BookingStates.IN_PROGRESS,
      BookingStates.COMPLETED,
      BookingStates.CANCELED,
      BookingStates.DECLINED,
      BookingStates.PENDING,
      BookingStates.PRICE_REQUEST,
    ];
  }

  constructor(
    private nodeApiService: NodeApiService,
    private http: HttpClient,
    private store: Store<any>,
    private storage: StorageService,
    private configService: ConfigService,
    private router: Router
  ) {
    this.store
      .pipe(
        select(getAuthToken),
        filter((accessToken) => accessToken !== undefined)
      )
      .subscribe((accessToken) => {
        if (accessToken) {
          this.accessToken = accessToken;
          this.getUser().subscribe((user) => {
            if (user) {
              this.store.dispatch(setCurrentUserAction({ data: user }));
            } else {
              this.store.dispatch(logoutAction());
            }
          });
        } else {
          this.store.dispatch(setUserAction({ data: null }));
          IntercomService.resetIntercom();
        }
      });
  }

  openInsta(currentUser: CurrentUser): string {
    if (currentUser?.id) {
      const roles = [1, 2, 3, 4];
      if (roles.includes(currentUser.role)) {
        if (currentUser.role === 1 || currentUser.role === 2) {
          if (!currentUser.live) {
            return 'INSTAFUCK_DIALOG.LOGIN_ACCOUNT';
          } else {
            this.router.navigateByUrl('/instafeature');

            return null;
          }
        } else if (currentUser.role === 3 || currentUser.role === 4) {
          if (!currentUser.live) {
            return 'INSTAFUCK_DIALOG.LOGIN_ACCOUNT';
          } else {
            this.router.navigateByUrl('/instafeature');

            return null;
          }
        }
      } else {
        return 'INSTAFUCK_DIALOG.WRONG_ROLE';
      }
    } else {
      return 'INSTAFUCK_DIALOG.LOGIN_ACCOUNT';
    }
  }

  // method who fill to user missing fields
  completeUserFields(user): any {
    if (!user.location) {
      user.location = null;
    }
    if (!user.availability) {
      user.availability = [];
    }
    if (!user.profile) {
      user.profile = {};
    }
    return user;
  }

  setAuth(user: UserLoginResult) {
    user.user = this.completeUserFields(user.user);

    user.user.cache_control = Date.now();
    user.user.locationStored = user.user.location;

    // Set isAuthenticated to true
    // this.store.dispatch(setUserAction({ data: user.user }));
  }

  attemptAuth(type, credentials): Observable<User> {
    const route = type === 'login' ? '/login' : '';
    return this.nodeApiService.post(route, credentials).pipe(
      map((data) => {
        this.setAuth(data.result);
        return data;
      })
    );
  }

  /**
   * press user login
   * @param credentials
   * @returns {Observable<User>}
   */
  attemptAuthPress(credentials): Observable<User> {
    return this.nodeApiService.post('/login', credentials).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getUserData() {
    return this.nodeApiService.get('/currentUser').pipe(
      map(
        (data) => {
          return <any>{
            user: data.result,
            status: data.status,
          };
        },
        (e) => {
          throw e;
        }
      ),
      tap((data) => {
        const sentryUser = data?.user
          ? {
              id: data.user.id.toString(10),
              email: data.user.email,
              username: data.user.username,
            }
          : null;
        Sentry.setUser(sentryUser);
      })
    );
  }

  getUser(): Observable<CurrentUser> {
    return this.http
      .get(`${this.configService.config.apiV3}/user/account/current`, {})
      .pipe(
        catchError(() => of(null)),
        tap((user: CurrentUser) => {
          const sentryUser = user
            ? {
                id: user.id.toString(10),
                email: user.email,
                username: user.displayName,
              }
            : null;
          Sentry.setUser(sentryUser);
        })
      );
  }

  getUserPreference(): Observable<UserPreferenceModel> {
    return this.http
      .get<UserPreferenceModel>(
        `${this.configService.config.apiV3}/user/preference`
      )
      .pipe(catchError(() => of(null)));
  }

  setUserPreference(
    command: SetUserPreferenceCommand
  ): Observable<UserPreferenceModel> {
    return this.http.patch<UserPreferenceModel>(
      `${this.configService.config.apiV3}/user/preference`,
      command
    );
  }

  forceRefreshUser(localState: any = null) {
    if (localState) {
      this.update(localState);
    } else {
      this.nodeApiService.get('/currentUser').subscribe(
        (data) => {
          if (data?.result) {
            this.update(data.result);
          }
        },
        (e) => {
          throw e;
        }
      );
    }
  }

  getUserStats() {
    return this.nodeApiService.post('/userStats').pipe(
      map(
        (data) => {
          return data.result;
        },
        (e) => {
          throw e;
        }
      )
    );
  }

  update(user: User): void {
    this.store.dispatch(setUserAction({ data: user }));
  }

  /**
   * check and save user role
   * @param user
   */
  checkUserRole(user: CurrentUser) {
    if (user) {
      const userRole: any = {
        isClient:
          user.role === UserRoles.CLIENT_BASIC ||
          user.role === UserRoles.CLIENT_PRO,
        isGingr:
          user.role === UserRoles.SERVICE_PROVIDER_BASIC ||
          user.role === UserRoles.SERVICE_PROVIDER_PRO,
        isEstablishment:
          user.role === UserRoles.ESTABLISHMENT_BASIC ||
          user.role === UserRoles.ESTABLISHMENT_PRO,
        isAgency:
          user.role === UserRoles.AGENCY_BASIC ||
          user.role === UserRoles.AGENCY_PRO,
      };
      this.roleSubject.next(userRole);
      if (
        user.role === UserRoles.SERVICE_PROVIDER_PRO ||
        user.role === UserRoles.CLIENT_PRO ||
        user.role === UserRoles.ESTABLISHMENT_PRO ||
        user.role === UserRoles.AGENCY_PRO
      ) {
        this.isProUserSubject.next(true);
      }
    } else {
      this.roleSubject.next({ isGuest: true });
    }
  }

  removeProUserRole() {
    this.isProUserSubject.next(false);
  }

  addToFavourite(id: number) {
    return this.http.post(
      `${this.configService.config.apiV3}/favourite/user/${id}`,
      {}
    );
  }

  deleteFromFavorite(id: number) {
    return this.http.delete(
      `${this.configService.config.apiV3}/favourite/user/${id}`
    );
  }

  cancelBooking(ref: string): Observable<any> {
    return this.nodeApiService.post('/bookings/cancel', { reference: ref });
  }

  getFavourites(userId: number) {
    this.nodeApiService
      .post('/listFavourite', { userId: userId })
      .subscribe((data) => {
        if (data !== undefined && data !== null) {
          this.followersSubject.next(data.result);
        }
      });
  }

  isProfileInFavourites(slug: string): boolean {
    return !!_.find(this.followersSubject.getValue(), { slug: slug });
  }

  getLocalLastSeen() {
    return this.storage.get('lastSeen', true) || [];
  }

  goToLive(slug: string, userLocation) {
    return this.nodeApiService.post('/goLive', { slug, userLocation });
  }

  /**
   * user forgot password
   * @param {object} data
   * @returns {Observable<any>}
   */
  forgotPassword(data) {
    return this.nodeApiService.post('/forgotPassword', data);
  }

  /**
   * user reset password
   * @param {object} data
   * @returns {Observable<any>}
   */
  resetPassword(data) {
    return this.nodeApiService.post('/resetPassword', data);
  }

  confirmIndependent(token: string, action: string) {
    let connectionUrl;
    if (action && action === 'accept') {
      connectionUrl = '/manageGingrs/checkIndependent';
    }
    return this.nodeApiService.post(connectionUrl, {
      token: token,
      action: action,
    });
  }

  /**
   * @param {string} token
   */
  declineTransfer(token: string) {
    return this.nodeApiService.post('/manageGingrs/declineTransfer', {
      token: token,
    });
  }

  setPasswordIndependentGingr(
    token: string,
    password: string,
    password_confirmation: string
  ) {
    return this.nodeApiService.post('/manageGingrs/setPassword', {
      token: token,
      password: password,
      password_confirmation: password_confirmation,
    });
  }

  setPasswordTransferGingr(
    token: string,
    sc: string,
    password: string,
    password_confirmation: string
  ) {
    return this.nodeApiService.post('/manageGingrs/confirmTransfer', {
      token: token,
      sc: sc,
      password: password,
      password_confirmation: password_confirmation,
    });
  }

  confirmEmail(token: string): Observable<any> {
    // Get request token in parameter
    return this.nodeApiService.get('/confirmationEmail?token=' + token).pipe(
      map((result) => {
        if (result.status === 200) {
          // set user Email verified if succeess
          this.store.dispatch(emailVerifyAction());
        }
        return result;
      })
    );
  }

  /**/
  activateEmailLogin(userData: object): Observable<any> {
    // Get request token in parameter
    return this.nodeApiService.post('/activateAccount', userData).pipe(
      map((data) => {
        if (data.result.user['2fa'] === 'DISABLED') {
          this.setAuth(data.result);
        }
        return data;
      })
    );
  }

  smsVerifiedSuccess() {
    this.store.dispatch(smsVerifyAction());
  }

  landLineVerifiedSuccess() {
    this.store.dispatch(phoneVerifyAction());
  }

  canBook(
    profileId: number,
    userLocation: { latitude: number; longitude: number }
  ): Observable<CanBookModel> {
    return this.http
      .post<CanBookModel>(
        `${this.configService.config.apiV3}/bookings/canBook`,
        {
          userId: profileId,
          latitude: userLocation?.latitude,
          longitude: userLocation?.longitude,
        }
      )
      .pipe(catchError(() => of(null)));
  }

  canBookInstant(slug: string) {
    return this.nodeApiService.post('/bookings/canBookInstant', {
      slug: slug,
    });
  }

  acceptBooking(ref: string, newState: number): Observable<any> {
    return this.nodeApiService.post('/addBookingNote', {
      reference: ref,
      state: newState,
    });
  }

  getBookingByRef(ref: string): Observable<any> {
    return this.nodeApiService.get(`/bookings/ref/${ref}`);
  }

  getInstafeatureBooking(id): Observable<any> {
    return this.nodeApiService.get(`/instafuck/session/${id}`);
  }

  getBookings(states: number[]): Observable<any> {
    return this.nodeApiService.post('/bookings/list', {
      state: states,
    });
  }

  getBooking(states: number[]) {
    return this.nodeApiService
      .post('/bookings/list', {
        state: states,
      })
      .subscribe((bookings) => {
        this.bookingsSubject.next(bookings.result.data);
      });
  }

  getAllBookings(states: number[], instaStates = []) {
    return this.nodeApiService
      .post('/bookings/list', {
        state: states,
        instaStates,
      })
      .subscribe((bookings) => {
        this.allBookingsSubject.next(bookings.result.data);
      });
  }

  getBookingsByDateRange(dateFrom: string, dateTo: string): Observable<any> {
    return this.nodeApiService.post('/bookings/list', {
      dateFrom: dateFrom,
      dateTo: dateTo,
      state: UserService.getAllAvailableStatuses(),
    });
  }

  getBookingsBySearchCriteria(criteria: string): Observable<any> {
    return this.nodeApiService.post('/bookings/list', {
      search: criteria,
      state: UserService.getAllAvailableStatuses(),
    });
  }

  getBookingDatesFromTo(from: string, to: string): Observable<any> {
    return this.nodeApiService.post('/bookings/usedDates', {
      dateFrom: from,
      dateTo: to,
    });
  }

  resetFirstRunWizard() {
    this.first_time_wizard = false;
  }

  shouldRunWizard() {
    return !this.first_time_wizard;
  }

  wizardRun() {
    this.first_time_wizard = true;
  }

  getClientProfilePreview(clientId: number): Observable<any> {
    const id = clientId.toString();
    return this.nodeApiService.get(`/client-profile/${id}`);
  }

  getUserAvatarFromApi(user: GridUserDetails) {
    return (
      user?.medias?.find((media) => media.isAvatar)?.media ||
      this.getUserDefaultAvatar()
    );
  }

  getUserCoverFromApi(user: GridUserDetails) {
    return (
      user?.medias?.find((media) => media.cover)?.media ||
      this.getUserDefaultAvatarShort()
    );
  }

  getCurrentUserAvatar(user: CurrentUser) {
    const avatarMedia = user?.medias?.find((media) => media.isAvatar)?.media;
    return avatarMedia
      ? `${this.configService.config.mediaCdnUrl.slice(0, -1)}${avatarMedia}`
      : this.getUserDefaultAvatar();
  }

  getUserMediaThumbUrl(userId, mediaId) {
    return `${this.configService.config.mediaCdnUrl}gingrs/${userId}_${mediaId}_thumb.jpg`;
  }

  getUserMediaUrl(userId, mediaId, ext = 'jpg', type = 'image') {
    return `${this.configService.config.mediaCdnUrl}gingrs/${userId}_${mediaId}_${type}.${ext}`;
  }

  getUserDefaultAvatar() {
    return `/assets/images/profile/avatar-default-picture.svg`;
  }

  getUserDefaultAvatarShort() {
    return `/assets/images/profile/avatar-default-pink.svg`;
  }

  mapSearchUsers(
    searchFilter: MapUserSearchCommand
  ): Observable<MapUserSearchResult[]> {
    return this.http.post<MapUserSearchResult[]>(
      `${this.configService.config.apiV3}/user/map/search`,
      searchFilter
    );
  }

  getMapUser(userId: number): Observable<MapUser> {
    return this.http.get<MapUser>(
      `${this.configService.config.apiV3}/user/map/${userId}`
    );
  }

  getChatUser(slug: string): Observable<ChatUser> {
    return this.http
      .post<ChatUser>(`${this.configService.config.apiV3}/user/chat`, { slug })
      .pipe(catchError(() => of(null)));
  }

  updateUserActivity(): Observable<any> {
    return this.http.post(
      `${this.configService.config.apiV3}/user/activity`,
      {}
    );
  }

  blockUser(userId: number): Observable<any> {
    return this.http
      .post(`${this.configService.config.apiV3}/user/${userId}/block`, {})
      .pipe(catchError(() => of(null)));
  }

  unBlockUser(userId: number): Observable<any> {
    return this.http
      .post(`${this.configService.config.apiV3}/user/${userId}/unblock`, {})
      .pipe(catchError(() => of(null)));
  }

  openInstantBooking(state: any = {}, instaUrl = '/instafeature') {
    if (!this.checkCreditBalance()) {
      return;
    }

    if (!this.user()) {
      this.router.navigate(['/instafeature', 'promo'], {
        state: { show: true },
      });
      return;
    } else if (
      this.user().profileApprovedStatus !== ProfileApprovedStatus.APPROVED
    ) {
      this.router.navigate(['/account/profile']);
      return;
    } else if (
      this.user().role === UserRoles.SERVICE_PROVIDER_BASIC ||
      this.user().role === UserRoles.SERVICE_PROVIDER_PRO
    ) {
      if (!this.userPreference().instantBookingPromoSkip) {
        this.setUserPreference({ instantBookingPromoSkip: true }).subscribe(
          (data) =>
            this.store.dispatch(
              setCurrentUserPreferenceAction({
                data,
              })
            )
        );
        this.router.navigate(['/instafeature', 'promo'], {
          state: { showRules: true, show: true },
        });
        return;
      } else if (!this.userPreference().instantBookingRulesSkip) {
        this.router.navigate(['/instafeature', 'rules'], {
          state: { showRules: true, show: true },
        });
        return;
      }
    }
    this.router.navigate([instaUrl], { state });
  }

  checkCreditBalance(): boolean {
    if (this.user()?.creditBalance >= 0) {
      return true;
    }

    if (
      this.user()?.role === UserRoles.CLIENT_PRO ||
      this.user()?.role === UserRoles.CLIENT_BASIC
    ) {
      return true;
    }

    if (
      this.user()?.role === UserRoles.SERVICE_PROVIDER_BASIC ||
      this.user()?.role === UserRoles.SERVICE_PROVIDER_PRO
    ) {
      if (this.instaState()?.id) {
        return true;
      }
      this.router.navigate(['/credit/balance']);
      return false;
    }

    return true;
  }

  private showNegativeBalanceToast() {
    this.notifyService.addToast({
      message: this.translation.translate(
        _t('CREDIT.Your credit balance is negative')
      ),
      type: ToastType.Error,
      timeout: 3000,
    });
  }
}
