<div
  class="filter bg-base-100 z-[100] fixed right-0 bottom-0 w-full md:w-[390px] top-0 md:top-[64px] overflow-auto pb-[100px]"
>
  <div class="fixedContent">
    <div
      class="flex justify-center relative text-white py-4 md:hidden bg-base-200"
    >
      <div class="insta_app-title--name">
        <span class="filter-header capitalize">{{
          'FILTER.Filter' | translate: locale.language
        }}</span>
      </div>
      <span class="insta_app-title--close">
        <img alt="close" (click)="closeFilter()" src="assets/icons/cross.svg" />
      </span>
    </div>

    <div
      class="filter-tabs"
      *ngIf="instaState?.callType === filterCall.OUTCALL"
    >
      <div
        (click)="locationSelected = false"
        [ngClass]="{
          selected: !locationSelected
        }"
        class="option"
      >
        <span class="capitalize">
          {{ 'INSTA.Offer' | translate: locale.language }}
        </span>
      </div>
      <div
        (click)="locationSelected = true"
        [ngClass]="{
          selected: locationSelected
        }"
        class="option"
      >
        <span> {{ 'ACCOUNT.Location' | translate: locale.language }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!locationSelected">
    <div
      class="flex flex-col md:pt-0 content-height"
      [ngClass]="
        instaState?.callType === filterCall.OUTCALL ? 'pt-[120px]' : 'pt-[60px]'
      "
    >
      <ng-container [formGroup]="formGroupTopTypes">
        <div class="px-5 pt-[32px] pb-[48px]">
          <div class="input-wrapper relative">
            <input
              formControlName="search"
              type="text"
              class="py-2.5 ps-2.5 pe-[40px] border-primary border-solid border-2 w-full text-white rounded bg-transparent focus:outline-0 filter-input"
              placeholder="{{ 'FILTER.Search' | translate: locale.language }}"
            />
            <img
              src="/assets/icons/filter-search.svg"
              class="searchIco absolute"
              alt="SearchIco"
            />
          </div>
        </div>
        <div class="list">
          <div class="check-list">
            <div class="flex">
              <svg-icon
                *ngIf="formGroupTopTypes.controls['favourites']?.value"
                src="/assets/svg-icons/filters/favourites.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <svg-icon
                *ngIf="!formGroupTopTypes.controls['favourites']?.value"
                src="/assets/svg-icons/filters/favourites.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#fff'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name"
                [ngClass]="
                  formGroupTopTypes.controls['favourites']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'COMMON.FAVOURITES' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="favourites"
                class="checkbox"
                id="favourites"
              />
            </div>
          </div>
          <div class="check-list">
            <div class="flex">
              <svg-icon
                *ngIf="formGroupTopTypes.controls['new']?.value"
                src="/assets/svg-icons/filters/new.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <svg-icon
                *ngIf="!formGroupTopTypes.controls['new']?.value"
                src="/assets/svg-icons/filters/new.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#fff'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name"
                [ngClass]="
                  formGroupTopTypes.controls['new']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'COMMON.NEW' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="new"
                class="checkbox"
                id="new"
              />
            </div>
          </div>
          <div class="check-list">
            <div class="flex">
              <svg-icon
                *ngIf="formGroupTopTypes.controls['verified']?.value"
                src="/assets/svg-icons/filters/verified.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <svg-icon
                *ngIf="!formGroupTopTypes.controls['verified']?.value"
                src="/assets/svg-icons/filters/verified.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#fff'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name capitalize"
                [ngClass]="
                  formGroupTopTypes.controls['verified']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'COMMON.VERIFIED' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="verified"
                class="checkbox"
                id="verified"
              />
            </div>
          </div>
          <div class="check-list">
            <div class="flex">
              <svg-icon
                *ngIf="formGroupTopTypes.controls['video']?.value"
                src="/assets/svg-icons/filters/video.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#ffd640'
                }"
                class="item-icon"
              ></svg-icon>
              <svg-icon
                *ngIf="!formGroupTopTypes.controls['video']?.value"
                src="/assets/svg-icons/filters/video.svg"
                [svgStyle]="{
                  'width.px': 21,
                  'height.px': 21,
                  fill: '#fff'
                }"
                class="item-icon"
              ></svg-icon>
              <span
                class="check-list_name"
                [ngClass]="
                  formGroupTopTypes.controls['video']?.value
                    ? 'text-primary'
                    : 'text-white'
                "
                >{{ 'FILTER.VIDEO' | translate: locale.language }}</span
              >
            </div>
            <div>
              <input
                type="checkbox"
                formControlName="video"
                class="checkbox"
                id="video"
              />
            </div>
          </div>
        </div>
        <div class="clip"></div>
        <div class="collapse grow">
          <div>
            <div
              class="flex justify-between collapse-parent mb-5 items-center cursor-pointer"
              (click)="expandItem('rating')"
            >
              <div class="flex flex-row items-center">
                <div class="w-5 h-5 flex justify-center items-center">
                  <svg-icon
                    *ngIf="!this.rating || this.rating === 1"
                    src="/assets/svg-icons/booking/review-positive.svg"
                    [svgStyle]="{
                      'width.px': 30,
                      'height.px': 30,
                      fill: 'rgb(255, 255, 255)'
                    }"
                  >
                  </svg-icon>
                  <svg-icon
                    *ngIf="this.rating && this.rating !== 1"
                    src="/assets/svg-icons/booking/review-positive.svg"
                    [svgStyle]="{
                      'width.px': 30,
                      'height.px': 30,
                      fill: '#ffd640'
                    }"
                  >
                  </svg-icon>
                </div>
                <span
                  class="text capitalize"
                  [ngClass]="
                    this.rating && this.rating !== 1
                      ? 'text-primary'
                      : 'text-white'
                  "
                  >{{ 'FILTER.Rating' | translate: locale.language }}</span
                >
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down cursor-pointer transition-all"
                  [class.rotate-90]="!expanded.rating"
                  [class.rotate-[-90deg]]="expanded.rating"
                />
              </div>
            </div>
            <div class="collapse-slider py-8 ps-9" *ngIf="expanded.rating">
              <ngx-slider
                [(value)]="rating"
                [options]="ratingOptions"
                (userChangeEnd)="changeRating()"
              ></ngx-slider>
            </div>
          </div>

          <div [formGroup]="genderFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('gender')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(genderFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/gender.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.GENDER' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/gender.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.GENDER' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.gender"
                  [class.rotate-[-90deg]]="expanded.gender"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.gender">
              <div class="list panel p-0">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let gender of config.sexes; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title capitalize"
                        [ngClass]="
                          genderFormGroup.controls[gender.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'COMMON.' + gender.sex_name
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="gender.id"
                      class="checkbox"
                      id="gender"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="sexualityFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('sexuality')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(sexualityFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/sexuality.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.SEXUALITY' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/sexuality.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.SEXUALITY' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.sexuality"
                  [class.rotate-[-90deg]]="expanded.sexuality"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.sexuality">
              <div class="list panel p-0">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let sexuality of config.sexualities; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          sexualityFormGroup.controls[sexuality.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          sexuality.sexuality_name | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="sexuality.id"
                      class="checkbox"
                      id="sexuality"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('price')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  *ngIf="priceLow !== 0 || priceHigh !== 500; else noPrice"
                  src="/assets/svg-icons/filters/price.svg"
                  [svgStyle]="{
                    'width.px': 21,
                    'height.px': 21,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <ng-template #noPrice>
                  <svg-icon
                    src="/assets/svg-icons/filters/price.svg"
                    [svgStyle]="{
                      'width.px': 21,
                      'height.px': 21,
                      fill: '#fff'
                    }"
                    class="item-icon"
                  ></svg-icon>
                </ng-template>
                <span
                  class="text"
                  [ngClass]="
                    priceLow !== 0 || priceHigh !== 500
                      ? 'text-primary'
                      : 'text-white'
                  "
                  >{{ 'FILTER.PRICES_HOUR' | translate: locale.language }}</span
                >
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.price"
                  [class.rotate-[-90deg]]="expanded.price"
                />
              </div>
            </div>
            <div class="collapse-slider" *ngIf="expanded.price">
              <ngx-slider
                [(value)]="priceLow"
                [(highValue)]="priceHigh"
                [options]="priceOptions"
                (userChangeEnd)="changePrices()"
              ></ngx-slider>
            </div>
          </div>
          <div>
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('age')"
            >
              <div class="flex flex-row items-center">
                <svg-icon
                  *ngIf="ageLow !== 18 || ageHigh !== 100; else noAge"
                  src="/assets/svg-icons/filters/age.svg"
                  [svgStyle]="{
                    'width.px': 21,
                    'height.px': 21,
                    fill: '#ffd640'
                  }"
                  class="item-icon"
                ></svg-icon>
                <ng-template #noAge>
                  <svg-icon
                    src="/assets/svg-icons/filters/age.svg"
                    [svgStyle]="{
                      'width.px': 21,
                      'height.px': 21,
                      fill: '#fff'
                    }"
                    class="item-icon"
                  ></svg-icon>
                </ng-template>
                <span
                  class="text"
                  [ngClass]="
                    ageLow !== 18 || ageHigh !== 100
                      ? 'text-primary'
                      : 'text-white'
                  "
                  >{{ 'FILTER.AGE' | translate: locale.language }}</span
                >
              </div>
              <div>
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.age"
                  [class.rotate-[-90deg]]="expanded.age"
                />
              </div>
            </div>
            <div class="collapse-slider" *ngIf="expanded.age">
              <ngx-slider
                [(value)]="ageLow"
                [(highValue)]="ageHigh"
                [options]="ageOptions"
                (userChangeEnd)="changeAge()"
              ></ngx-slider>
            </div>
          </div>
          <div class="services-panel" [formGroup]="servicesFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('services')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(servicesFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/services.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.SERVICES' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/services.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.SERVICES' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.services"
                  [class.rotate-[-90deg]]="expanded.services"
                />
              </div>
            </div>
            <div class="w-100 pb-[32px]" *ngIf="expanded.services">
              <div
                class="content serviceType"
                *ngFor="let serviceType of servicesType"
              >
                <span
                  class="text-primary text-[16px] mb-[16px] block mt-[32px] capitalize"
                  >{{ serviceType.name | translate: locale.language }}</span
                >
                <div
                  class="check-list flex justify-between items-center"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="
                    let service of servicesByCategory[serviceType.type];
                    let i = index
                  "
                >
                  <div class="language">
                    <div>
                      <span
                        class="title capitalize"
                        [ngClass]="
                          servicesFormGroup.controls[service.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          ('SERVICES.' + service.name + '.NAME'
                            | translate: locale.language) +
                            (service.activity === 'ACTIVE'
                              ? ' (' +
                                ('FILTER.Active' | translate: locale.language) +
                                ')'
                              : service.activity === 'PASSIVE'
                                ? ' (' +
                                  ('FILTER.Passive'
                                    | translate: locale.language) +
                                  ')'
                                : '')
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="service.id"
                      class="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="languagesFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('languages')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(languagesFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/languages.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.LANGUAGES' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/languages.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.LANGUAGES' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.languages"
                  [class.rotate-[-90deg]]="expanded.languages"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.languages">
              <div class="list panel">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let language of config.languages; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          languagesFormGroup.controls[language.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          'COMMON.LANGUAGES.' + language.id
                            | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="language.id"
                      class="checkbox"
                      id="language"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="hairColorFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('hairColor')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(hairColorFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/hair-color.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.HAIR_COLOR' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/hair-color.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.HAIR_COLOR' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.hairColor"
                  [class.rotate-[-90deg]]="expanded.hairColor"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.hairColor">
              <div class="list panel">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let hairColor of config.haircolors; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          hairColorFormGroup.controls[hairColor.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          hairColor.haircolors_name | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="hairColor.id"
                      class="checkbox"
                      id="hairColor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="ethnicityFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('ethnicity')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(ethnicityFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/ethnicity.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.ETHNICITY' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/ethnicity.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.ETHNICITY' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.ethnicity"
                  [class.rotate-[-90deg]]="expanded.ethnicity"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.ethnicity">
              <div class="list panel">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let origin of config.origins; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          ethnicityFormGroup.controls[origin.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          origin.origin_name | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="origin.id"
                      class="checkbox"
                      id="ethnicity"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="bodyTypeFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('bodyType')"
            >
              <div class="flex flex-row items-center">
                <ng-container [ngSwitch]="formHasSelected(bodyTypeFormGroup)">
                  <ng-container *ngSwitchCase="true">
                    <svg-icon
                      src="/assets/svg-icons/filters/body-type.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#ffd640'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-primary">{{
                      'FILTER.BODY_TYPE' | translate: locale.language
                    }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <svg-icon
                      src="/assets/svg-icons/filters/body-type.svg"
                      [svgStyle]="{
                        'width.px': 21,
                        'height.px': 21,
                        fill: '#fff'
                      }"
                      class="item-icon"
                    ></svg-icon>
                    <span class="text text-white">{{
                      'FILTER.BODY_TYPE' | translate: locale.language
                    }}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.bodyType"
                  [class.rotate-[-90deg]]="expanded.bodyType"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.bodyType">
              <div class="list panel">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let bodyType of config.bodies; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          bodyTypeFormGroup.controls[bodyType.id]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{
                          bodyType.bodytypes_name | translate: locale.language
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="bodyType.id"
                      class="checkbox"
                      id="bodyType"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [formGroup]="breastSizeFormGroup">
            <div
              class="flex justify-between collapse-parent"
              (click)="expandItem('breastSize')"
            >
              <div class="flex flex-row items-center">
                <div class="flex justify-center items-center">
                  <ng-container
                    [ngSwitch]="formHasSelected(breastSizeFormGroup)"
                  >
                    <ng-container *ngSwitchCase="true">
                      <svg-icon
                        src="/assets/svg-icons/filters/breast-size.svg"
                        [svgStyle]="{
                          'width.px': 21,
                          'height.px': 21,
                          fill: '#ffd640'
                        }"
                        class="item-icon"
                      ></svg-icon>
                      <span class="text text-primary">{{
                        'FILTER.Breast_size' | translate: locale.language
                      }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <svg-icon
                        src="/assets/svg-icons/filters/breast-size.svg"
                        [svgStyle]="{
                          'width.px': 21,
                          'height.px': 21,
                          fill: '#fff'
                        }"
                        class="item-icon"
                      ></svg-icon>
                      <span class="text text-white">{{
                        'FILTER.Breast_size' | translate: locale.language
                      }}</span>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="cursor-pointer">
                <img
                  src="assets/images/icons/arrow-right-yellow.svg"
                  alt="arrow-down"
                  class="arrow-down transition-all"
                  [class.rotate-90]="!expanded.breastSize"
                  [class.rotate-[-90deg]]="expanded.breastSize"
                />
              </div>
            </div>
            <div class="w-100" *ngIf="expanded.breastSize">
              <div class="list panel">
                <div
                  class="check-list"
                  [ngClass]="{ 'mTop-0': i === 0 }"
                  *ngFor="let breastSize of breastSizes; let i = index"
                >
                  <div class="language">
                    <div>
                      <span
                        class="title"
                        [ngClass]="
                          breastSizeFormGroup.controls[breastSize]?.value
                            ? 'text-primary'
                            : 'text-white'
                        "
                        >{{ breastSize }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      [formControlName]="breastSize"
                      class="checkbox"
                      id="breastSize"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="locationSelected"
    class="pt-[140px] xl:pt-3 px-[25px] w-sm-screen"
  >
    <div class="text-primary pb-[6px]">
      {{ 'BOOKINGS.Meeting Point' | translate: locale.language }}
    </div>
    <div
      class="location-content py-2.5 ps-2.5 pe-[40px] border-primary border-solid border-2 w-full text-white rounded bg-transparent focus:outline-0 relative"
      (click)="openMap()"
    >
      <div class="location-container">
        {{ (bookingAddress$ | async) || (userLocationAddress$ | async) }}
      </div>
      <img alt="close" src="assets/icons/cross.svg" class="change-add" />
    </div>
  </div>

  <div
    class="flex flex-col text-primary cursor-pointer py-3 px-5 bg-base-300 items-center shadow-xxs fixed md:w-[390px] bottom-0 right-0 left-0 md:left-auto"
  >
    <div
      class="flex items-center self-end gap-0.5 mb-3"
      (click)="resetFilter()"
      *ngIf="!locationSelected"
    >
      <img alt="reset" src="assets/icons/resetFilters.svg" class="reset me-2" />
      {{ 'FILTER.Reset_Filter' | translate: locale.language }}
    </div>
    <div class="w-100">
      <button
        class="bg-primary rounded py-2 w-full text-primary-content capitalize text-lg font-medium"
        (click)="goToGingrsList()"
      >
        {{
          '(' +
            resultsCount +
            ') ' +
            ((resultsCount === 1 ? 'FILTER.Result' : 'FILTER.Results')
              | translate: locale.language)
        }}
      </button>
    </div>
  </div>
</div>

<div
  class="fixed h-[calc(100%-64px)] w-[calc(100%-390px)] hidden md:block z-[51] bg-[rgba(0,0,0,0.69)] top-[64px] left-0 cursor-pointer"
  (click)="closeFilter()"
></div>
