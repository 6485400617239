export interface Config {
  app_config: AppConfigItem[];
  bodies: Array<any>;
  bodyArt: any;
  bookingat: Array<any>;
  bookingstates: Array<any>;
  countries: Array<any>;
  conditionals: Array<any>;
  csrfToken: string;
  currencies: Array<any>;
  establishmentservices: Array<any>;
  eyecolors: Array<any>;
  haircolors: Array<any>;
  hears: Array<any>;
  instafuckSettings: Array<any>;
  languages: Array<any>;
  location_by_ip: any;
  origins: Array<any>;
  positions: Array<any>;
  prefix: Array<any>;
  prefixes: Array<any>;
  prices: Array<any>;
  promotionstype: Array<any>;
  services: Array<any>;
  sexes: Array<any>;
  sexualities: Array<any>;
  status: number;
  talias: Array<any>;
  travels: Array<any>;
  whatareyou: Array<any>;
  timestamp: number;
}

export enum AppConfig {
  INSTA_PENDING_CONFIRMATION = 'INSTA_PENDING_CONFIRMATION',
  INSTA_END_CONFIRMATION = 'INSTA_END_CONFIRMATION',
  TRAVEL_PRICE_PER_KM = 'TRAVEL_PRICE_PER_KM',
  TRAVEL_PRICE_MIN = 'TRAVEL_PRICE_MIN',
  ESCORT_FEE_BOOK = 'ESCORT_FEE_BOOK',
  ESCORT_FEE_INSTA = 'ESCORT_FEE_INSTA',
}

export interface AppConfigItem {
  config_property: string;
  config_value: string;
}
