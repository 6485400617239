import { createSelector } from '@ngrx/store';
import { SharedState } from '@app/shared/reducers/shared.reducer';
import * as _ from 'lodash-es';
import { AppConfig, AppConfigItem } from '@app/shared/models';

export function getSharedState(state): SharedState {
  return state.shared;
}

export const getAppConfig = createSelector(
  getSharedState,
  (state: SharedState) => state?.appConfig
);

export const getServiceFeeAppointment = createSelector(
  getSharedState,
  (state: SharedState) =>
    +state?.appConfig?.app_config?.find(
      (item: AppConfigItem) =>
        item.config_property === AppConfig.ESCORT_FEE_BOOK
    )?.config_value || 0
);

export const getCountryCategory = createSelector(
  getSharedState,
  (state: SharedState) => {
    let data =
      state?.appConfig?.countries !== undefined
        ? state.appConfig.countries
        : [];
    data = _.orderBy(data, ['countryName'], ['asc']);
    return data;
  }
);

export const getPrices = createSelector(
  getSharedState,
  (state: SharedState) => state?.appConfig?.prices
);

// getUserLocation is not necessary to real location of the device,
// but the location user selects in the search bar
export const getUserLocation = createSelector(
  getSharedState,
  (state: SharedState) => state?.userLocation
);

export const getLanguageList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const systemLanguages = Array.isArray(state?.appConfig?.languages)
      ? state.appConfig.languages
      : [];
    return systemLanguages.filter((lang: any) => {
      return lang.appLanguage === 1;
    });
  }
);

export const getAllLanguageList = createSelector(
  getSharedState,
  (state: SharedState) => {
    const results = Array.isArray(state?.appConfig?.languages)
      ? state.appConfig.languages.filter((each) => {
          return each.name !== 'N/A';
        })
      : [];

    return _.orderBy(_.orderBy(results, ['name'], ['asc']), (item) =>
      item.name === 'Swiss German'
        ? 1
        : item.name === 'German'
          ? 2
          : item.name === 'English'
            ? 3
            : item.name === 'Spanish'
              ? 4
              : item.name === 'Portuguese'
                ? 5
                : item.name === 'Romanian'
                  ? 6
                  : 7
    );
  }
);

export const getNationalityList = createSelector(
  getSharedState,
  (state: SharedState) => {
    let data = [];
    if (state?.appConfig?.prefixes !== undefined) {
      data = state.appConfig.prefixes;
      data.forEach((item, index) => {
        // Remove items that don't have nationality
        if (item.nationality === null || item.primary === 0) {
          data.splice(index, 1);
        }
      });
    }

    // Avoid duplicates by adding to a Set and converting back to an Array
    data = Array.from(new Set(data));
    const distinctData = data.filter((item, i, arr) => {
      return (
        arr.indexOf(arr.find((t) => t.nationality === item.nationality)) === i
      );
    });
    return _.orderBy(
      _.orderBy(distinctData, ['nationality'], ['asc']),
      (item) =>
        item.nationality === 'Swiss'
          ? 1
          : item.nationality === 'German'
            ? 2
            : item.nationality === 'Romanian'
              ? 3
              : 4
    );
  }
);

export const getLocationByIp = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.location_by_ip !== undefined
      ? state.appConfig.location_by_ip
      : {}
);

export const getPhonePrefixes = createSelector(
  getSharedState,
  (state: SharedState) => {
    let data =
      state?.appConfig?.prefixes !== undefined ? state.appConfig.prefixes : [];
    data = _.orderBy(data, ['countryName'], ['asc']);
    return data;
  }
);

export const getOriginsList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.origins !== undefined ? state.appConfig.origins : []
);

export const getSexualitiesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.sexualities !== undefined
      ? state.appConfig.sexualities
      : []
);

export const getGenitaliaAreaList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.talias !== undefined ? state.appConfig.talias : []
);

export const getHearsList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.hears !== undefined ? state.appConfig.hears : []
);

export const getHairColorsList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.haircolors !== undefined ? state.appConfig.haircolors : []
);

export const getEyeColorsList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.eyecolors !== undefined ? state.appConfig.eyecolors : []
);

export const getBodiesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.bodies !== undefined ? state.appConfig.bodies : []
);

export const getBodiesArtList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.bodyArt !== undefined ? state.appConfig.bodyArt : []
);

export const getServicesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.services !== undefined ? state.appConfig.services : []
);

export const getCurrenciesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.currencies !== undefined ? state.appConfig.currencies : []
);

export const getEstServicesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.establishmentservices !== undefined
      ? state.appConfig.establishmentservices
      : []
);

export const getSexesList = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.appConfig?.sexes !== undefined ? state.appConfig.sexes : []
);

export const getGridProfilesFilters = createSelector(
  getSharedState,
  (state: SharedState) => state?.gridProfilesFilters
);
