import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';
import { Observable, of } from 'rxjs';
import {
  NotificationModel,
  NotificationSenderModel,
  NotificationSubcategory,
} from '@app/shared/models/notification.model';
import { catchError } from 'rxjs/operators';
import { BookingStates } from '@app/shared/models/enum/bookingstates';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  NotificationSettingModel,
  NotificationSettingType,
} from '@app/shared/models/notification-setting.model';
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from '@angular/fire/messaging';
import { NotificationService } from '@app/shared/services/notification.service';
import { ToastType } from '@app/shared/models/toast.model';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationsService {
  readonly notificationService = inject(NotificationService);

  isListeningToPush = false;
  notificationRequested = false;

  static getNotificationUrl(
    subcategory: NotificationSubcategory,
    slug: string,
    additionalId?: number
  ): string {
    switch (subcategory) {
      case NotificationSubcategory.PROFILE_NOT_APPROVED:
        return '/account/profile';
      case NotificationSubcategory.PROFILE_NOT_VERIFIED:
        return '/account/profile/verification';
      case NotificationSubcategory.CLIENT_ACCEPT_PRICE:
      case NotificationSubcategory.CLIENT_DECLINE_PRICE:
      case NotificationSubcategory.CLIENT_PRICE_REQUEST:
      case NotificationSubcategory.GINGR_PRICE_OFFER:
        return '/account/bookings';
      case NotificationSubcategory.NEW_BOOKING:
      case NotificationSubcategory.GINGR_ACCEPT_BOOKING:
      case NotificationSubcategory.GINGR_DECLINE_BOOKING:
      case NotificationSubcategory.GINGR_BOOKING_REMINDER:
      case NotificationSubcategory.CLIENT_BOOKING_REMINDER:
      case NotificationSubcategory.CANCEL_BOOKING:
        return `/account/bookings/${additionalId}`;
      case NotificationSubcategory.BOOKING_REVIEW:
        return '/account/reviews';
      case NotificationSubcategory.CONNECTION_REQUEST:
        return '/workplace';
      case NotificationSubcategory.CONNECTION_REQUEST_ACCEPTED_MANAGED:
      case NotificationSubcategory.GINGR_RECONNECT_MANAGED:
        return `/manage-gingrs/${slug}`;
      case NotificationSubcategory.CONNECTION_REQUEST_DECLINED:
      case NotificationSubcategory.DISCONNECTION_INDEPENDENT:
      case NotificationSubcategory.DISCONNECTION_MANAGED:
      case NotificationSubcategory.CONNECTION_REQUEST_ACCEPTED_INDEPENDENT:
      case NotificationSubcategory.GINGR_RECONNECT_INDEPENDENT:
        return '/manage-gingrs';
      case NotificationSubcategory.NEGATIVE_CREDIT_BALANCE:
      case NotificationSubcategory.LOW_CREDIT_BALANCE:
        return '/credit/balance';
    }

    return undefined;
  }

  static getNotificationParams(notification) {
    switch (notification.subcategory) {
      case NotificationSubcategory.NEW_BOOKING:
      case NotificationSubcategory.CLIENT_PRICE_REQUEST:
      case NotificationSubcategory.GINGR_PRICE_OFFER:
        return {
          bookingState: BookingStates.PENDING,
          bookingId: notification.additionalId,
        };
      case NotificationSubcategory.GINGR_ACCEPT_BOOKING:
      case NotificationSubcategory.CLIENT_ACCEPT_PRICE:
      case NotificationSubcategory.CLIENT_BOOKING_REMINDER:
      case NotificationSubcategory.GINGR_BOOKING_REMINDER:
        return {
          bookingState: BookingStates.ACCEPTED,
          bookingId: notification.additionalId,
        };
      case NotificationSubcategory.GINGR_DECLINE_BOOKING:
      case NotificationSubcategory.CLIENT_DECLINE_PRICE:
        return {
          bookingState: BookingStates.DECLINED,
          bookingId: notification.additionalId,
        };
      case NotificationSubcategory.CANCEL_BOOKING:
        return {
          bookingState: BookingStates.CANCELED,
          bookingId: notification.additionalId,
        };
      case NotificationSubcategory.DISCONNECTION_INDEPENDENT:
      case NotificationSubcategory.CONNECTION_REQUEST_ACCEPTED_INDEPENDENT:
      case NotificationSubcategory.GINGR_RECONNECT_INDEPENDENT:
        return {
          independent: 1,
        };
    }

    return {};
  }

  constructor(
    private injector: EnvironmentInjector,
    public http: HttpClient,
    public configService: ConfigService,
    private deviceService: DeviceDetectorService
  ) {}

  getNotifications(
    skip: number,
    takeCount: number,
    startDate?: string
  ): Observable<NotificationModel[]> {
    return this.http
      .get<
        NotificationModel[]
      >(`${this.configService.config.apiV3}/notifications?skip=${skip}&take=${takeCount}${startDate ? '&startDate=' + startDate : ''}`)
      .pipe(catchError(() => of([])));
  }

  markRead(notificationIds: number[]): Observable<any> {
    return this.http.post(
      `${this.configService.config.apiV3}/notifications/read`,
      { notificationIds }
    );
  }

  markViewed(notificationIds: number[]): Observable<any> {
    return this.http.post(
      `${this.configService.config.apiV3}/notifications/view`,
      { notificationIds }
    );
  }

  getUnViewedNotifications(): Observable<number> {
    return this.http.get<number>(
      `${this.configService.config.apiV3}/notifications/not-viewed`
    );
  }

  requestPushToken(): void {
    if (this.notificationRequested) {
      return;
    }
    this.notificationRequested = true;

    runInInjectionContext(this.injector, () => {
      const fireMessaging = inject(Messaging);
      getToken(fireMessaging)
        .then((token) => {
          this.uploadPushToken(token).subscribe();
          this.isListeningToPush = true;
          this.listenToPushMessages();
        })
        .catch((err) => {});
    });
  }

  listenToPushMessages(): void {
    const messaging = getMessaging();
    onMessage(messaging, {
      next: (message) => {
        if (message.data?.sender) {
          message.data.sender = JSON.parse(message.data.sender as string);
        }
        if (message.data.category) {
          (message.data.category as any) = +message.data.category;
        }
        if (message.data.subcategory) {
          (message.data.subcategory as any) = +message.data.subcategory;
        }
        if (message.data.additionalId) {
          (message.data.additionalId as any) = +message.data.additionalId;
        }

        if (message.notification?.body || message.notification?.title) {
          message.data.link = UserNotificationsService.getNotificationUrl(
            +message.data.subcategory,
            (message.data.sender as any as NotificationSenderModel)?.senderSlug
          );

          const params = UserNotificationsService.getNotificationParams(
            message.data
          );
          this.notificationService.addToast({
            message: message.notification?.body,
            timeout: 6000,
            type: ToastType.Info,
            redirectLink: message.data.link,
            redirectParams: { state: params },
          });
        }
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  uploadPushToken(token: string): Observable<any> {
    const deviceInfo = this.deviceService.getDeviceInfo();

    return this.http.post(
      `${this.configService.config.apiV3}/notifications/push/token`,
      { token: token, deviceName: `${deviceInfo?.os} ${deviceInfo?.browser}` }
    );
  }

  getNotificationSettings(): Observable<NotificationSettingModel[]> {
    return this.http
      .get<
        NotificationSettingModel[]
      >(`${this.configService.config.apiV3}/notifications/settings`)
      .pipe(catchError(() => of([])));
  }

  switchNotificationSetting(
    notificationType: NotificationSettingType,
    enabled: boolean
  ): Observable<boolean> {
    return this.http
      .patch<boolean>(
        `${this.configService.config.apiV3}/notifications/settings`,
        { notificationType, enabled }
      )
      .pipe(catchError(() => of(false)));
  }

  getTelegramToken(): Observable<{ token: string }> {
    return this.http
      .get<{
        token: string;
      }>(
        `${this.configService.config.apiV3}/notifications/settings/telegram/token`
      )
      .pipe(catchError(() => of(null)));
  }
}
