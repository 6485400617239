export const ServiceTypes: any = [
  {
    name: <string>'ACCOUNT.SERVICES.common',
    type: <number>1,
  },
  {
    name: <string>'ACCOUNT.SERVICES.popular',
    type: <number>2,
  },
  {
    name: <string>'ACCOUNT.SERVICES.general',
    type: <number>3,
  },
  {
    name: <string>'ACCOUNT.SERVICES.extreme',
    type: <number>4,
  },
];
