import { inject, Injectable } from '@angular/core';
import { getCurrentUser } from '@app/shared/reducers/user.selectors';
import { Store } from '@ngrx/store';
import { setIntercomVisible } from '@app/shared/actions/shared.actions';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  readonly store = inject(Store);
  private currentUser = this.store.selectSignal(getCurrentUser);

  private static getBaseIntercomPayload(): any {
    return {
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: 'y58oyckt',
    };
  }

  static resetIntercom() {
    const windowVar = window as any;
    windowVar.Intercom('boot', IntercomService.getBaseIntercomPayload());
    windowVar.Intercom('shutdown');
  }

  private static bootIntercom(bootPayload: any) {
    const windowVar = window as any;
    windowVar.Intercom('boot', bootPayload);
  }

  constructor() {}

  intercom(show = false) {
    let bootPayload = IntercomService.getBaseIntercomPayload();
    if (this.currentUser()) {
      bootPayload = {
        ...bootPayload,
        user_hash: this.currentUser().intercomHmac,
        name: this.currentUser().displayName,
        email: this.currentUser().email,
      };
    }
    this.store.dispatch(setIntercomVisible({ data: true }));
    IntercomService.bootIntercom(bootPayload);
    if (show) {
      (window as any).Intercom('show');
    }
  }
}
