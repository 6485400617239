import { createAction, props } from '@ngrx/store';
import { LocationModel } from '@app/instafeature/models/location';
import { ServiceProvidersSearch } from '@app/instafeature/models/service-provider-search';
import { GingrCountingData } from '@app/instafeature/models/gingr-counting-data';
import { InstaState, InstaSteps } from '@app/instafeature/models/insta-state';
import { StartedInstaFromEnum } from '@app/instafeature/models/started-insta-from.enum';

export const getGingrCountInArea = createAction('[Insta] Get gingr count');

export const getClientCountInArea = createAction('[Insta] Get client count');

export const getClientCountByLocation = createAction(
  '[Insta] Get client count by location'
);

export const saveGingrCountInArea = createAction(
  '[Insta] Save gingr count',
  props<{ gingrs: GingrCountingData }>()
);

export const saveClientCountInArea = createAction(
  '[Insta] Save client count',
  props<{ clients: number }>()
);

export const setInstaStepAction = createAction(
  '[Insta] Set insta step',
  props<{ step: number }>()
);

export const saveLocation = createAction(
  '[Insta] Save location',
  props<{ location: LocationModel }>()
);

export const saveServiceProviders = createAction(
  '[Insta] Save service providers',
  props<{ serviceProviders: ServiceProvidersSearch[] }>()
);

export const getInstaState = createAction(
  '[Insta] Get insta state',
  props<{ skipRedirect: boolean; checkBalance?: boolean }>()
);

export const saveInstaState = createAction(
  '[Insta] Save insta state',
  props<{ instaState: InstaState }>()
);

export const deleteActiveBooking = createAction(
  '[Insta] Delete active booking'
);

export const saveDirections = createAction(
  '[Insta] Save directions',
  props<{ directions }>()
);

export const getProfile = createAction(
  '[Insta] Get profile',
  props<{ id: number }>()
);

export const getProfileExtraDetails = createAction(
  '[Insta] Get profile extra details',
  props<{ id: number }>()
);

export const saveProfile = createAction(
  '[Insta] Save profile',
  props<{ profile }>()
);

export const saveUnreadMessagesNumber = createAction(
  '[Insta] Save unreal messages number',
  props<{ unreadMessagesNumber: number }>()
);

export const saveUserConnected = createAction(
  '[Insta] Save user connected',
  props<{ userConnected: boolean }>()
);

export const startBookingForProfile = createAction(
  '[Insta] Start incall booking for profile',
  props<{ profileId: number }>()
);

export const addInfoToBooking = createAction(
  '[Insta] Add incall address to booking'
);

export const lastStepCompletedAction = createAction(
  '[Insta] Set last step completed',
  props<{ lastStepCompleted: InstaSteps }>()
);

export const openInstaMap = createAction('[Insta] Toggle insta map');

export const checkIntervalsExtend = createAction(
  '[Insta] Get available intervals for extend'
);

export const saveIntervalsExtend = createAction(
  '[Insta] Save available intervals for extend',
  props<{ maxDuration: number }>()
);

export const setStartedInstaFromAction = createAction(
  '[Insta] Set started insta from',
  props<{ startedInstaFrom: StartedInstaFromEnum }>()
);

export const saveTravelDistance = createAction(
  '[Insta] Save travel distance',
  props<{ travelDistance: number; distanceId: string }>()
);
