import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getAppConfigAction,
  getCurrentUserAction,
  getCurrentUserPreferenceAction,
  getHasNotificationSetAction,
  getKycVerificationAction,
  getUnViewedNotificationsAction,
  getUserLocationAction,
  logoutAction,
  setAuthTokenAction,
  setCurrentUserAction,
  setCurrentUserPreferenceAction,
  setHasNotificationSetAction,
  setIntercomVisible,
  setKycVerificationAction,
  setUnViewedNotificationsAction,
  setUserAction,
  storeAppConfigAction,
  storeUserLocationAction,
} from '@app/shared/actions/shared.actions';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  AppService,
  LocationService,
  UserNotificationsService,
  UserService,
} from '@app/shared/services';
import { Config, SummaryLocation } from '@app/shared/models';
import { Auth } from 'aws-amplify';
import { from, of, zip } from 'rxjs';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapService } from '@app/instafeature/services/map.service';
import { Store } from '@ngrx/store';
import { getUserLocation } from '@app/shared/reducers/shared.selectors';
import { NotificationSettingType } from '@app/shared/models/notification-setting.model';
import { IntercomService } from '@app/shared/services/intercom.service';
import { getIntercomVisible } from '@app/shared/reducers/user.selectors';
import { DataspikeService } from '@app/shared/services/dataspike.service';

@Injectable({
  providedIn: 'root',
})
export class SharedEffects {
  private readonly intercomService = inject(IntercomService);
  private readonly userService = inject(UserService);
  private readonly dataspikeService = inject(DataspikeService);

  getAppConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppConfigAction),
      switchMap(() => this.appService.getAppConfig()),
      map((data: Config) => {
        this.appService.saveInStorage(data);
        return storeAppConfigAction({ data });
      })
    )
  );

  getHasNotificationSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[getHasNotificationSetAction, setCurrentUserAction]),
      switchMap(() => this.userNotificationService.getNotificationSettings()),
      map((data) => {
        if (
          data.some(
            (e) =>
              e.notificationType === NotificationSettingType.Push && !!e.enabled
          )
        ) {
          this.userNotificationService.requestPushToken();
        } else {
          this.userNotificationService.isListeningToPush = false;
        }

        return setHasNotificationSetAction({
          hasNotificationSet: data.some(
            (e) =>
              !!e.enabled &&
              (!e.uninitialized ||
                e.notificationType !== NotificationSettingType.Telegram)
          ),
        });
      })
    )
  );

  getKycVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKycVerificationAction),
      switchMap(() => this.dataspikeService.getVerification()),
      map((data) => setKycVerificationAction({ verification: data }))
    )
  );

  getUserLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserLocationAction),
      switchMap(({ askAccess }) =>
        this.geolocationService.getUserLocation(askAccess)
      ),
      map((data: SummaryLocation) => {
        this.mapService.setMapStyle(data);
        return storeUserLocationAction({ data });
      })
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      map(() => from(Auth.signOut())),
      switchMap(() => {
        this.userService.removeProUserRole();
        IntercomService.resetIntercom();
        return [
          setUserAction({ data: null }),
          setAuthTokenAction({ accessToken: null }),
          setCurrentUserPreferenceAction({ data: null }),
          setIntercomVisible({ data: false }),
        ];
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUserAction),
      switchMap(() => this.userService.getUser()),
      map((result) => setCurrentUserAction({ data: result }))
    )
  );

  getUserPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[setCurrentUserAction, getCurrentUserPreferenceAction]),
      switchMap(() => this.userService.getUserPreference()),
      map((result) => setCurrentUserPreferenceAction({ data: result }))
    )
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentUserAction),
      switchMap((action) =>
        zip(of(action), this.store.select(getUserLocation))
      ),
      map(([action, userLocation]) => {
        const deviceInfo = this.deviceService.getDeviceInfo();
        const deviceTypeContext = {
          kind: 'deviceType',
          key: deviceInfo.deviceType,
        };

        const multiContext: any = {
          kind: 'multi',
          user: action.data
            ? {
                kind: 'user',
                key: action.data.idPid,
                name: action.data.displayName,
                email: action.data.email,
              }
            : {
                kind: 'user',
                anonymous: true,
                key: 'anon-user',
              },
          deviceType: deviceTypeContext,
        };

        if (userLocation) {
          multiContext.location = {
            kind: 'location',
            key: userLocation.country,
            country: userLocation.country,
            countryName: userLocation.countryname,
          };
        }

        StaticUtilsService.LdClient.identify(multiContext);

        if (action.data) {
          this.userService.updateUserActivity().subscribe();
        }

        this.userService.checkUserRole(action.data);
        return action.data
          ? getUnViewedNotificationsAction()
          : setUnViewedNotificationsAction({ count: 0 });
      })
    )
  );

  getUnViewedNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUnViewedNotificationsAction),
      switchMap(() => this.userNotificationService.getUnViewedNotifications()),
      map((count: number) => setUnViewedNotificationsAction({ count }))
    )
  );

  updateIntercom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentUserAction),
      filter((data) => !!data?.data),
      withLatestFrom(this.store.select(getIntercomVisible)),
      filter(([payload, intercomVisible]) => !!intercomVisible),
      map((_) => {
        this.intercomService.intercom();
        return null;
      }),
      filter((action) => !!action)
    )
  );

  constructor(
    private actions$: Actions,
    private appService: AppService,
    private userNotificationService: UserNotificationsService,
    private geolocationService: LocationService,
    private deviceService: DeviceDetectorService,
    private mapService: MapService,
    private store: Store
  ) {}
}
