import { Subject, combineLatest, Observable } from 'rxjs';
import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
  Input,
  inject,
} from '@angular/core';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  RouterLink,
} from '@angular/router';
import * as _ from 'lodash-es';
import { MediaObserver } from '@angular/flex-layout';
import {
  ModalService,
  FilterService,
  LocationService,
  ProfilesService,
  UserService,
} from '@app/shared/services';
import { UserAvailableRoles } from '@app/shared/models';
import { AccountMenuService, AccountService } from '@app/account/services';
import { DialogRegisterRoleComponent } from '@app/shared/components/dialog-login-register/dialog-login-register.component';
import { DialogPressLoginComponent } from 'app/shared/components/dialog-press-login/dialog-press-login.component';
import { InfoPressPageComponent } from '@app/info/containers/info-press-page/info-press-page.component';
import {
  L10N_CONFIG,
  L10N_LOCALE,
  L10nConfig,
  L10nLocale,
  L10nTranslationService,
  L10nTranslatePipe,
} from 'angular-l10n';
import { ConfigService } from '@app/shared/services/config.service';
import { select, Store } from '@ngrx/store';
import { getLanguageList } from '@app/shared/reducers/shared.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import {
  getCurrentUserAction,
  logoutAction,
} from '@app/shared/actions/shared.actions';
import {
  getCurrentUser,
  getUnViewedNotifications,
} from '@app/shared/reducers/user.selectors';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AccountMenuComponent } from '../account-menu/account-menu.component';
import { LocationSelectorComponent } from '../location-selector/location-selector.component';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  AsyncPipe,
  JsonPipe,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { CurrentUser } from '@app/shared/models/current-user';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { FilterV2Service } from '@app/shared/services/filter-v2.service';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { FeatureFlags } from '@app/shared/models/constants';
import { InstaBookingPromoComponent } from '@app/shared/components/insta-booking-promo/insta-booking-promo.component';
import { InstaBookingRulesComponent } from '@app/shared/components/insta-booking-rules/insta-booking-rules.component';
import { openInstaMap } from '@app/instafeature/store/instafeature.actions';
import { InstafeatureFilterService } from '@app/instafeature/services/instafeature-filter.service';

@Component({
  selector: 'gingr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    FlexModule,
    ExtendedModule,
    NgIf,
    RouterLink,
    SvgIconComponent,
    NgTemplateOutlet,
    LocationSelectorComponent,
    AccountMenuComponent,
    MatIconModule,
    MatButtonModule,
    L10nTranslatePipe,
    MatMenuModule,
    NgForOf,
    JsonPipe,
    InstaBookingPromoComponent,
    InstaBookingRulesComponent,
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class HeaderComponent implements OnInit, AfterContentInit, OnDestroy {
  private readonly instafeatureFilterService = inject(
    InstafeatureFilterService
  );

  @Output() navToggle = new EventEmitter<boolean>();
  @Output() filterToggle = new EventEmitter<boolean>();
  @Output() themeToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() light: EventEmitter<any> = new EventEmitter();
  @Output() dark: EventEmitter<any> = new EventEmitter();

  @Output() openNav: EventEmitter<any> = new EventEmitter();
  @Output() closeNav: EventEmitter<any> = new EventEmitter();
  @Input() disabled: boolean = true;
  @Input() isMenuClosed: boolean = false;

  public currentUser: CurrentUser;
  public isMobile: boolean;
  public isPreICO = this.configService.config.isPreICO === 'true';

  routerState: string;
  isAuthenticated: boolean = false;
  isMenuOpen: boolean;
  showLogo: boolean = false;
  isProUser: boolean = false;

  showFilterIcon: boolean = true;
  showMapIcon: boolean = true;
  showGridIcon: boolean = true;
  isInsta = true;
  filtersSelectedMainGrid$: Observable<number>;
  filtersSelectedInsta$: Observable<number>;

  roleLink: string;

  prefLangKey = 'prefLang';
  fullLanguageName: string = 'English';

  // userLocation: UserLocation;
  languages = [
    {
      country: 'gb',
      value: 'en',
      viewValue: 'English',
    },
    {
      country: 'de',
      value: 'de',
      viewValue: 'German',
    },
    {
      country: 'bg',
      value: 'bg',
      viewValue: 'Bulgarian',
    },
    {
      country: 'es',
      value: 'es',
      viewValue: 'Spanish',
    },
    {
      country: 'fr',
      value: 'fr',
      viewValue: 'French',
    },
    {
      country: 'hu',
      value: 'hu',
      viewValue: 'Hungarian',
    },
    {
      country: 'it',
      value: 'it',
      viewValue: 'Italian',
    },
    {
      country: 'nl',
      value: 'nl',
      viewValue: 'Dutch',
    },
    {
      country: 'pt',
      value: 'pt',
      viewValue: 'Portuguese',
    },
    {
      country: 'ro',
      value: 'ro',
      viewValue: 'Romanian',
    },
    {
      country: 'ru',
      value: 'ru',
      viewValue: 'Russian',
    },
    {
      country: 'th',
      value: 'th',
      viewValue: 'Thai',
    },
  ];

  changeLanguage: boolean;
  languageList: any[];

  elementSettings = {
    location: {
      width: 20,
      show: false,
    },
    accountMenu: {
      width: 40,
      show: false,
    },
    createFreeProfileButton: {
      show: true,
    },
    pressLoginButton: {
      show: false,
    },
    pressLogoutButton: {
      show: false,
    },
  };

  unViewedNotifications: number;

  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/';
  public navigationText: string;

  public schema = this.l10nConfig.schema;

  private destroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private store: Store<any>,
    private accountService: AccountService,
    private observableMedia: MediaObserver,
    private modalService: ModalService,
    private geolocation: LocationService,
    private configService: ConfigService,
    private menuService: AccountMenuService,
    @Inject(L10N_CONFIG) public l10nConfig: L10nConfig,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translationService: L10nTranslationService,
    public userService: UserService,
    public filterService: FilterService,
    public profilesService: ProfilesService,
    private filterV2Service: FilterV2Service
  ) {}

  navOpen() {
    this.navToggle.emit(true);
    this.isMenuOpen = !this.isMenuOpen;
  }
  filterOpen() {
    this.filterToggle.emit(true);
  }

  ngAfterContentInit() {
    combineLatest([
      this.menuService.accountHeader,
      this.observableMedia.asObservable(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([headerText, change]) => {
        this.navigationText = headerText;
        this.isMobile = change[0].mqAlias === 'xs';
        switch (change[0].mqAlias) {
          case 'xs':
            this.closeNav.emit();
            this.isMenuOpen = false;
            !this.navigationText
              ? this.setShowLogo(true)
              : this.setShowLogo(false);
            break;
          case 'sm':
          case 'md':
            this.closeNav.emit();
            this.isMenuOpen = false;
            this.setShowLogo(true);
            break;
          case 'lg':
            this.openNav.emit();
            this.isMenuOpen = true;
            this.setShowLogo(true);
            break;
          case 'xl':
            this.openNav.emit();
            this.isMenuOpen = true;
            this.setShowLogo(true);
            break;
        }
      });
  }

  ngOnInit(): void {
    this.filtersSelectedMainGrid$ =
      this.filterV2Service.filtersSelected.asObservable();
    this.filtersSelectedInsta$ =
      this.instafeatureFilterService.filtersSelected.asObservable();

    this.changeLanguage = StaticUtilsService.LdClient.variation(
      FeatureFlags.ChangeLanguage,
      false
    );

    this.store
      .pipe(select(getLanguageList), takeUntil(this.destroy$))
      .subscribe((data) => (this.languageList = data));

    this.store
      .pipe(select(getUnViewedNotifications), takeUntil(this.destroy$))
      .subscribe((data) => (this.unViewedNotifications = data));

    this.checkRoute();
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      if (e instanceof NavigationEnd || e instanceof NavigationStart) {
        this.checkRoute();
      }
    });

    // check if user is pro
    this.userService.isProUser.pipe(takeUntil(this.destroy$)).subscribe(
      (userProStatus) => {
        setTimeout(() => {
          this.isProUser = userProStatus;
        }, 100);
      },
      (errors) => {
        this.isProUser = false;
      }
    );

    this.store
      .pipe(
        select(getCurrentUser),
        filter((user) => user !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.isAuthenticated = !!user;

        if (user) {
          this.elementSettings.location.width = 20;
          this.elementSettings.accountMenu.show = true;

          localStorage.removeItem(this.prefLangKey);
          this.currentUser = _.cloneDeep(user);
          this.setLang(this.currentUser.langIso);
        } else {
          this.currentUser = null;
          const prefLang = localStorage.getItem(this.prefLangKey);
          if (prefLang) {
            setTimeout(() => {
              this.setLang(prefLang);
            }, 100);
          }
          this.elementSettings.location.width = 60;
          this.elementSettings.accountMenu.show = false;
        }

        this.checkElementsSettings();
      });
  }

  redirectToMainPage() {
    if (this.router.url.includes('/instafeature')) {
      return;
    }

    this.router.navigate(['/gingrs']);
  }

  checkRoute() {
    if (this.isMobile && this.isMenuOpen) {
      this.closeNav.emit();
      this.isMenuOpen = false;
    }
    this.routerState = this.router.url;

    this.showMapIcon =
      this.routerState &&
      (this.routerState === '/gingrs' ||
        this.routerState === '/gingrs/profile' ||
        this.routerState === '/agencies' ||
        this.routerState === '/establishments' ||
        this.routerState.includes('/instafeature/find-gingr'));

    this.showFilterIcon =
      this.routerState &&
      (this.routerState === '/gingrs' ||
        this.routerState === '/agencies' ||
        this.routerState === '/establishments' ||
        this.routerState.includes('/instafeature/find-gingr'));

    this.showGridIcon =
      this.routerState &&
      this.routerState.indexOf('map') !== -1 &&
      this.routerState.indexOf('profile') === -1;

    this.isInsta = this.routerState.includes('/instafeature');

    this.checkElementsSettings();
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public checkIsOnState(url: string): boolean {
    return this.routerState.indexOf(url) !== -1;
  }

  /**
   * check location and account menu blocks settings
   */
  checkElementsSettings(): void {
    this.elementSettings.location.show =
      (this.routerState && this.routerState.indexOf('map') !== -1) ||
      this.routerState === '/gingrs' ||
      this.routerState === '/establishments' ||
      this.routerState === '/agencies';

    if (this.routerState === '/' + InfoPressPageComponent.pageUrl) {
      this.elementSettings.createFreeProfileButton.show = false;
      this.elementSettings.pressLoginButton.show = true;
      if (this.isAuthenticated) {
        this.showPresLoginLoginByRole();
      } else {
        this.elementSettings.pressLoginButton.show = true;
        this.elementSettings.pressLogoutButton.show = false;
      }
    } else {
      this.elementSettings.createFreeProfileButton.show = true;
      this.elementSettings.pressLoginButton.show = false;
    }

    if (this.routerState && this.routerState.indexOf('account') > 0) {
      this.elementSettings.accountMenu.show = true;
    } else {
      this.elementSettings.accountMenu.show = false;
    }

    if (this.elementSettings.accountMenu.show) {
      this.elementSettings.location.width = 20;
    } else {
      this.elementSettings.location.width = 60;
    }
  }

  /**
   * show press login form on press page for auth user
   * with not press user role too
   */
  public showPresLoginLoginByRole() {
    if (this.currentUser.role === UserRoles.CLIENT_PRESS) {
      this.elementSettings.pressLoginButton.show = false;
      this.elementSettings.pressLogoutButton.show = true;
    } else {
      this.elementSettings.pressLoginButton.show = true;
      this.elementSettings.pressLogoutButton.show = false;
    }
  }

  selectLocale(language: string, fullLanguage: string): void {
    if (this.currentUser) {
      const languageModel = _.find(this.languageList, {
        languages_name: language,
      });
      if (languageModel) {
        this.currentUser.language = languageModel.id;
        this.currentUser.langIso = languageModel.languages_name;

        this.accountService
          .updateAccountLanguage(this.currentUser.language)
          .subscribe(() => this.store.dispatch(getCurrentUserAction()));
      }
    } else {
      localStorage.setItem(this.prefLangKey, language);
    }
    this.setLang(language);
  }

  setLang(lang: string) {
    const item = this.schema.find((e) => e.locale.language === lang);
    const selectedItem = this.languages.find((el) => el.value === lang);
    this.fullLanguageName = selectedItem.viewValue;
    if (item) {
      this.translationService.setLocale(item.locale);
    }
  }

  mapLang(lang): string {
    if (lang === 'en') {
      return 'gb';
    }
    return lang;
  }

  openDialog(type: string): void {
    if (type === 'press') {
      this.modalService.openDialog(DialogPressLoginComponent);
    } else {
      this.modalService.openDialog(DialogRegisterRoleComponent, {
        type: type,
        role: UserAvailableRoles.client,
      });
    }
  }

  public logout() {
    this.store.dispatch(logoutAction());
    this.router.navigateByUrl('/welcome');
  }

  public backToGrid() {
    this.router.navigate([this.filterV2Service.currentRole]);
    this.profilesService.pushProfiles([]);
  }

  public goToMap() {
    if (!this.showMapIcon) {
      return;
    }
    if (this.isInsta) {
      this.store.dispatch(openInstaMap());
      return;
    }
    this.router.navigate([this.filterV2Service.currentRole, 'map']);
  }

  locationChanged(location) {
    this.geolocation.setUserPosition(location);
  }

  private setShowLogo(value: boolean) {
    setTimeout(() => (this.showLogo = value), 100);
  }

  openInstantBookingPromo() {
    this.userService.openInstantBooking();
  }
}
