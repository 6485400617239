import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Config } from '../../shared/models';
import { ApiService } from './api.service';
import { NodeApiService } from './node-api.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { SiteMap } from '@app/shared/models/sitemap';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private re: RegExp = new RegExp('.+(ch|at|sex|es|love)');

  constructor(
    private apiService: ApiService,
    private nodeApi: NodeApiService,
    private storage: StorageService
  ) {}

  getToken() {
    return this.storage.get('appToken');
  }

  saveToken(token: String) {
    this.storage.set('appToken', token);
  }

  saveInStorage(data: any) {
    data.timestamp = Math.floor(Date.now() / 1000);
    this.saveToken(data.csrfToken);
  }

  getAppConfig(): Observable<Config> {
    return this.nodeApi.get('/config').pipe(
      map(
        (data) => {
          return data?.result;
        },
        (e) => {
          throw e;
        }
      )
    );
  }

  getAppSiteMap(): Observable<SiteMap> {
    return this.apiService.get('/sitemap').pipe(
      map(
        (data) => {
          return data;
        },
        (e) => {
          throw e;
        }
      )
    );
  }

  getJSON(path: string): Observable<JSON> {
    return this.apiService.getLocalAsset(path);
  }

  getTld(): string {
    return 'ch';
  }
}
