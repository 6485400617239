import { InjectionToken } from '@angular/core';
import { ConfigModel } from '@app/shared/models/config.model';

export interface ConstantsModel {
  maxImageSize: number;

  maxVideoSize: number;
}

export const constants: ConstantsModel = {
  // 10 mb
  maxImageSize: 1024 * 1024 * 15,

  // 60 mb
  maxVideoSize: 1024 * 1024 * 60,
};

export const APP_CONFIG: InjectionToken<ConfigModel> =
  new InjectionToken<ConfigModel>('Application Configuration');

export const CacheGingrProfiles = 'profilesGingr';
export const CacheGingrProfilesScroll = 'scrollGingr';
export const CacheEstablishmentProfiles = 'profilesEstablishment';
export const CacheEstablishmentProfilesScroll = 'scrollEstablishment';
export const CacheAgencyProfiles = 'profilesAgency';
export const CacheAgencyProfilesScroll = 'scrollAgency';
export const CacheDeviceLocation = 'deviceLocation';

export enum FeatureFlags {
  InstaContinue = 'insta-continue',
  InstaStickyPathing = 'insta-sticky-pathing',
  CookieBar = 'cookie-bar',
  Over18 = 'over-18',
  EnableBookings = 'enable-bookings',
  InstaDesktop = 'insta-desktop',
  PageSubscription = 'page-subscription',
  PageGGCoins = 'page-gg-coins',
  TestBookingDuration = 'test-booking-duration',
  InstaRecalculateRoute = 'insta-recalculate-route',
  ShowBuildId = 'show-build-id',
  WebSocketStatusNotifications = 'web-socket-status-notifications',
  NotifyPageReload = 'notify-page-reload',
  ChangeLanguage = 'change-language',
  HideClientReviews = 'hide-client-reviews',
  RestrictMeetingSwitzerland = 'restrict-meeting-switzerland',
  PressReleases = 'press-releases',
}
