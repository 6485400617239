import { SharedState } from '@app/shared/reducers/shared.reducer';
import { createSelector } from '@ngrx/store';
import { UserService } from '@app/shared/services';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { ProfileApprovedStatus } from '@app/shared/models/current-user';

export function getSharedState(state): SharedState {
  return state.shared;
}

export const getUser = createSelector(
  getSharedState,
  (state: SharedState) => state?.user
);

export const getCurrentUser = createSelector(
  getSharedState,
  (state: SharedState) => state?.currentUser
);

export const hasNotificationSet = createSelector(
  getSharedState,
  (state: SharedState) => state?.hasNotificationSet
);

export const getKycVerification = createSelector(
  getSharedState,
  (state: SharedState) => state?.kcyVerification
);

export const getCurrentUserPreference = createSelector(
  getSharedState,
  (state: SharedState) => state?.currentUserPreference
);

export const isUserClient = createSelector(
  getSharedState,
  (state: SharedState) =>
    !state?.currentUser
      ? undefined
      : +state?.user?.role === UserRoles.CLIENT_BASIC ||
        +state?.user?.role === UserRoles.CLIENT_PRO
);

export const acceptedLocations = createSelector(
  getSharedState,
  (state: SharedState) =>
    !state?.currentUser?.pricing?.outcall
      ? {}
      : {
          hotel: state.currentUser.pricing.outcall.hotel,
          home: state.currentUser.pricing.outcall.home,
          events: state.currentUser.pricing.outcall.events,
        }
);
export const getUserRole = createSelector(
  getSharedState,
  (state: SharedState) => UserService.getUserRole(state?.user)
);

export const isProUser = createSelector(
  getSharedState,
  (state: SharedState) => {
    const proRolesIds: Array<number> = [2, 4, 6, 9];
    let isPro = false;
    if (!state.user) {
      return false;
    }

    if (state?.user?.role) {
      isPro = proRolesIds.some((item) => {
        return item === +state.user.role;
      });
    }

    return isPro;
  }
);

export const has2fa = createSelector(
  getSharedState,
  (state: SharedState) =>
    state?.user?.['2fa'] &&
    state.user['2fa'] !== 'DISABLED' &&
    state.user['2fa'] !== 'COMPLETED'
);

export const getManagedUser = createSelector(
  getSharedState,
  (state: SharedState) => state?.managedUser
);

export const getAuthToken = createSelector(
  getSharedState,
  (state: SharedState) => state?.accessToken
);

export const getUnViewedNotifications = createSelector(
  getSharedState,
  (state: SharedState) => state?.unViewedNotifications
);

export const getFilterCallTypeSelector = createSelector(
  getSharedState,
  (state: SharedState) => state?.filterCallType
);

export const getFilterLocationSelector = createSelector(
  getSharedState,
  (state: SharedState) => state?.filterLocation
);

export const getIntercomVisible = createSelector(
  getSharedState,
  (state: SharedState) => state?.intercomVisible
);

export const isUserProfileNotApproved = createSelector(
  getSharedState,
  (state: SharedState) =>
    state.currentUser?.profileApprovedStatus ===
    ProfileApprovedStatus.NOT_APPROVED
);
